<template>
  <div style="height: 64vh; width: 100%">
    <l-map
      v-model="zoom"
      v-model:zoom="zoom"
      :zoomAnimation="true"
      :options="{ zoomControl: false }"
      :center="[-6.304159, 106.724997]"
    >
      <!-- <div
        class="card bg-white rounded position-absolute ms-5 mt-10"
        style="z-index: 2"
      >
        <div class="card-body border-top p-5">
          <div class="fs-5 fw-bolder">Keterangan :</div>
          <ul class="list-unstyled mb-0">
            <li v-for="(item, i) in info" :key="i" class="m-0 p-0">
              <img class="me-1" :src="item.img" width="40" />
              <span class="fs-8"> {{ item.text }} </span>
            </li>
          </ul>
        </div>
      </div> -->
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      />
      <l-control-layers />
      <l-control-zoom position="bottomright"></l-control-zoom>

      <template v-for="(item, index) in markers" :key="index">
        <!-- icon -->
        <l-marker :lat-lng="[item.movement.latitude, item.movement.longitude]">
          <l-icon :icon-url="icon" :icon-size="iconSize" />
          <l-tooltip>
            {{ item.movement.alamat }}
          </l-tooltip>
          <l-popup>
            <div class="card">
              <div class="card-body text-start">
                <div class="fs-5 fw-bolder">
                  {{ item.territory.name }}
                </div>
                <div class="fw-bold">oleh : {{ item.created_by.name }}</div>
                <div class="text-muted">
                  {{ item.movement.date }}
                </div>
                <div class="">
                  {{ item.movement.description }}
                </div>
                <img
                  class="img-fluid"
                  style="max-height: 100px"
                  :src="item.movement.foto"
                  alt="foto kegiatan"
                />
              </div>
            </div>
          </l-popup>
        </l-marker>
      </template>
    </l-map>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LTooltip,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "CalegMap-Map",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LTooltip,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Sapa Rakyat Door to Door");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllMovementDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/advokasii.png";

    // const markers = ref([
    //   {
    //     id: 1,
    //     position: [-6.30219, 106.725977],
    //     icon: path + "other/png/advokasii.png",
    //     data: {
    //       name: "Caleg A",
    //       keterangan: "Relawan Kampanye di",
    //       tanggal: "30 Agustus 2021",
    //       img: path + "other/png/Rectangle -12.png",
    //     },
    //   },
    //   {
    //     id: 2,
    //     position: [-6.303169, 106.724987],
    //     icon: path + "other/png/advokasii-1.png",
    //     data: {
    //       name: "Caleg B",
    //       keterangan: "Relawan Kampanye di",
    //       tanggal: "8 Januari 2021",
    //       img: path + "other/png/Rectangle -12.png",
    //     },
    //   },
    //   {
    //     id: 3,
    //     position: [-6.305169, 106.724987],
    //     icon: path + "other/png/advokasii-2.png",
    //     data: {
    //       name: "Caleg C",
    //       keterangan: "Sosialisasi pemilu di",
    //       tanggal: "9 September 2021",
    //       img: path + "other/png/Rectangle -13.png",
    //     },
    //   },
    // ]);

    const markers = computed(() => store.state.MovementDashboardModule.all);

    // const info = ref([
    //   {
    //     img: path + "other/png/advokasii.png",
    //     text: "Caleg 1",
    //   },
    //   {
    //     img: path + "other/png/advokasii-1.png",
    //     text: "Caleg 2",
    //   },
    //   {
    //     img: path + "other/png/advokasii-2.png",
    //     text: "Caleg 3",
    //   },
    // ]);

    return {
      center,
      markers,
      // info,
      icon,
      zoom,
      iconSize,
    };
  },
});
</script>
